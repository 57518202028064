/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import Utils from "./../Utils";
import services from "./../services";
import cloneDeep from "lodash/cloneDeep";
import router from "./../router/index";

const AUTHORIZATION_ERROR_STATUS = 401;
const strict = true;

const getDefaultState = () => {
  return {
    projectList: [],
    activeProject: {},
    activeProjectThumbnail: ""
  };
};

const state = getDefaultState();

const getters = {
  getProjectList: state => state.projectList,
  getActiveProject: state => state.activeProject,
  getActiveProjectId: state => state.activeProject._id,
  getActiveProjectThumbnail: state => state.activeProjectThumbnail
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_PROJECT_LIST(state, projectList) {
    state.projectList = projectList;
  },
  SET_ACTIVE_PROJECT(state, project) {
    state.activeProject = project;
  },

  SET_PROJECT_THUMBNAIL(state, thumbnail) {
    state.activeProjectThumbnail = thumbnail;
  },
  SET_PROJECT_TYPE(state, type) {
    state.activeProject = {
      ...state.activeProject,
      type
    };
  }
};

const actions = {
  retrieveProjectsByClientId({ commit, rootGetters }) {
    const clientId = rootGetters["auth/getClientId"];
    return services.ProjectService.retrieveProjectsByClientId(clientId)
      .then(response => {
        // console.log(response);
        return groupProjects(response);
      })
      .then(projects => {
        commit("SET_PROJECT_LIST", projects);
      })
      .catch(error => {
        const isAuthorizationError =
          error.status === AUTHORIZATION_ERROR_STATUS;
        checkRedirectionByStatus(error.status, commit);
        commit("SET_PROJECT_LIST", []);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject(isAuthorizationError);
      });
  },
  retrieveProjectByClientId({ commit, rootGetters }, projectId) {
    const clientId = rootGetters["auth/getClientId"];
    return services.ProjectService.retrieveProjectByClientId(
      clientId,
      projectId
    )
      .then(response => {
        // console.log(response);
        setProject(commit, response);
        return response;
      })
      .then(response => {
        commit("SET_ACTIVE_PROJECT", response);
      })
      .catch(error => {
        const isAuthorizationError =
          error.status === AUTHORIZATION_ERROR_STATUS;
        checkRedirectionByStatus(error.status, commit);
        commit("SET_ACTIVE_PROJECT", {});
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject(isAuthorizationError);
      });
  },
  createProjectByClientId({ commit, rootGetters }, branchId) {
    const clientId = rootGetters["auth/getClientId"];
    const payload = getCreateProjectPayload(rootGetters);
    if (branchId) {
      payload.branchId = branchId;
    }
    console.log("Payload for project creation: ",payload)
    return services.ProjectService.createProjectByClientId(clientId, payload)
      .then(response => {
        commit("SET_ACTIVE_PROJECT", {});
        router.push({
          name: "Audience",
          params: { id: response._id }
        });
      })
      .catch(error => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  updateProjectByClientId({ commit, getters, dispatch, rootGetters }) {
    const clientId = rootGetters["auth/getClientId"];
    const projectId = getters.getActiveProjectId;
    let project = cloneDeep(getters.getActiveProject);
    const wizardActiveStep = rootGetters["wizard/getActiveStep"];
    project = getUpdateProjectPayload(project, wizardActiveStep, rootGetters);
    return services.ProjectService.updateProjectByClientId(
      clientId,
      projectId,
      project
    )
      .then(response => {
        // console.log(response);
        commit("SET_ACTIVE_PROJECT", response);
      })
      .catch(error => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        console.log(error);
        return Promise.reject();
      });
  },
  completeProject({ commit, getters, rootGetters }) {
    const clientId = rootGetters["auth/getClientId"];
    const projectId = getters.getActiveProjectId;
    let project = cloneDeep(getters.getActiveProject);
    project = getFullUpdatedProject(project, rootGetters);

    return services.ProjectService.updateProjectByClientId(
      clientId,
      projectId,
      project
    )
      .then(response => {
        // console.log(response);
        commit("SET_ACTIVE_PROJECT", response);
      })
      .catch(error => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          {},
          {
            root: true
          }
        );
        console.log(error);
        return Promise.reject();
      });
  },
  deleteProjectByClientId({ commit, getters, rootGetters }, projectId) {
    const clientId = rootGetters["auth/getClientId"];
    // const projectId = getters.getActiveProjectId;
    return services.ProjectService.deleteProjectByClientId(clientId, projectId)
      .then(response => {
        // console.log(response);
        commit("SET_ACTIVE_PROJECT", {});
      })
      .catch(error => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  buyProject({ commit, getters, rootGetters }) {
    const clientId = rootGetters["auth/getClientId"];
    const projectId = getters.getActiveProjectId;
    return services.ProjectService.buyProject(clientId, projectId).catch(
      error => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      }
    );
  },
  setProjectThumbnail({ commit, getters, rootGetters }) {
    const clientId = rootGetters["auth/getClientId"];
    const projectId = getters.getActiveProjectId;

    const thumbnail = getters.getActiveProjectThumbnail;
    return services.ProjectService.setProjectThumbnail(
      clientId,
      projectId,
      thumbnail
    )
      .then(response => {
        // console.log(response);
        commit("SET_ACTIVE_PROJECT", response);
        commit("SET_PROJECT_THUMBNAIL", response.thumbnail);
      })
      .catch(error => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  retrieveProjectFeasibility({ commit, getters, rootGetters }) {
    const clientId = rootGetters["auth/getClientId"];
    const projectId = getters.getActiveProjectId;

    return services.ProjectService.retrieveProjectFeasibility(
      clientId,
      projectId
    )
      .then(response => {
        return response;
      })
      .catch(error => {
        console.log(error);
        checkRedirectionByStatus(error.status, commit);
        let titleText = null;
        let messageText = null;
        if (error.status === 400) {
          titleText = "page.checkout.error.maxUploadedVideos.title";
          messageText = "page.checkout.error.maxUploadedVideos.message";
        }

        commit(
          "error/SET_ERROR",
          {
            status: error.status,
            titleText,
            messageText
          },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  // TODELETE
  dummyRetrieveProjectAssets({ commit, rootGetters }, { projectId }) {
    return services.ProjectService.retrieveProjectAssets(projectId)
      .then(response => {
        return response;
      })
      .catch(error => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  }
};

function getCreateProjectPayload(rootGetters) {
  const clientId = rootGetters["auth/getClientId"];
  const name = rootGetters["wizard/getActiveProjectName"];
  const type = rootGetters["wizard/getActiveProjectPackage"];
  const country = rootGetters["audience/getSelectedCountry"];
  const population = rootGetters["audience/getTargetGroup"];
  const panelProvider = rootGetters["audience/getselectedPanelProvider"]  
  const question = {};
  const genre = {
    female: true,
    male: true
  };

  // const step = rootGetters["wizard/getLastActiveStep"];
  const step = Utils.WIZARD_STEPS.AUDIENCE;
  const thumbnail = "";
  return {
    clientId,
    name,
    type,
    panelProvider,
    audience: {
      country,
      population,
      genre
    },
    question,
    step,
    thumbnail
  };
}

function getFullUpdatedProject(project, rootGetters) {
  project = getUpdatedAudience(project, rootGetters);
  // project = getUpdatedCheckout(project, rootGetters);
  project = getUpdatedQuestions(project, rootGetters);
  project.status = "active";

  return project;
}

function getUpdateProjectPayload(project, wizardActiveStep, rootGetters) {
  if (wizardActiveStep === Utils.WIZARD_STEPS.AUDIENCE) {
    project = getUpdatedAudience(project, rootGetters);
  } else if (wizardActiveStep === Utils.WIZARD_STEPS.CHECKOUT) {
    project = getUpdatedCheckout(project, rootGetters);
  } else if (wizardActiveStep === Utils.WIZARD_STEPS.QUESTIONS) {
    project = getUpdatedQuestions(project, rootGetters);
  } else if (wizardActiveStep === Utils.WIZARD_STEPS.COMPLETED) {
    project.status = "active";
  }

  project.step = rootGetters["wizard/getLastActiveStep"];

  return project;
}

function getUpdatedAudience(project, rootGetters) {
  const selectedCountry = rootGetters["audience/getSelectedCountry"];
  project.viewer = rootGetters["audience/getSelectedViewer"];
  project.audience.country = selectedCountry.id;
  project.audience.language =
    selectedCountry.supportedLanguages[0].languageIsoCode;

  project.panelProvider = rootGetters["audience/getselectedPanelProvider"] ;

  const brandCategory = rootGetters["audience/getBrandCategory"];
  if (brandCategory) project.audience.category = brandCategory;

  project.audience.population = rootGetters["audience/getTargetGroup"];
  const genderList = rootGetters["audience/getSelectedGenderList"];
  project.audience.genre.male =
    genderList.findIndex(gender => gender === "male") > -1;
  project.audience.genre.female =
    genderList.findIndex(gender => gender === "female") > -1;
  project.audience.refineAudience =
    rootGetters["audience/getActiveRefineAudience"];
  const ageList = rootGetters["audience/getSelectedAgeRangeList"];
  project.audience.ageGroup.group18_25 =
    ageList.findIndex(age => age === "group18_25") > -1;
  project.audience.ageGroup.group26_35 =
    ageList.findIndex(age => age === "group26_35") > -1;
  project.audience.ageGroup.group36_45 =
    ageList.findIndex(age => age === "group36_45") > -1;
  project.audience.ageGroup.group46_55 =
    ageList.findIndex(age => age === "group46_55") > -1;
  project.audience.ageGroup.group56_75 =
    ageList.findIndex(age => age === "group56_75") > -1;
  project.audience.ageGroup.group18_34 =
    ageList.findIndex(age => age === "group18_34") > -1;
  project.audience.ageGroup.group35_54 =
    ageList.findIndex(age => age === "group35_54") > -1;
  project.audience.ageGroup.group55_64 =
    ageList.findIndex(age => age === "group55_64") > -1;

  const selectedRegionList = rootGetters["audience/getSelectedRegionList"];
  project.audience.region = selectedRegionList.reduce(
    (accumulator, currentValue) => {
      accumulator.push(currentValue.option_id);
      return accumulator;
    },
    []
  );
  project.audience.regionsAttributeId = selectedRegionList[0].question_id;

  return project;
}

function getUpdatedCheckout(project, rootGetters) {
  project.payment.company = rootGetters["checkout/getCreditCardCompany"];
  project.payment.vat = rootGetters["checkout/getCreditCardVATNumber"];
  project.payment.address = rootGetters["checkout/getCreditCardAddress"];
  project.payment.country = rootGetters["checkout/getCreditCardCountryName"];
  project.payment.zip = rootGetters["checkout/getCreditCardPostalCode"];
  project.payment.city = rootGetters["checkout/getCreditCardCity"];
  project.payment.province = rootGetters["checkout/getCreditCardProvince"];
  project.payment.recipientCode =
    rootGetters["checkout/getCreditCardRecipientCode"];
  project.payment.pec = rootGetters["checkout/getCreditCardPEC"];
  project.payment.po = rootGetters["checkout/getCreditCardPurchaseOrderNumber"];
  project.payment.acceptedTerms =
    rootGetters["checkout/isCreditCardAcceptTermsSelected"];

  return project;
}

function getUpdatedQuestions(project, rootGetters) {
  const questionList = rootGetters["question/getQuestionList"];
  project.questions = [];
  let projectQuestionInfo;
  questionList.forEach(question => {
    projectQuestionInfo = {};
    projectQuestionInfo = {};
    projectQuestionInfo.type = question.type;
    projectQuestionInfo.text = question.text;
    if (
      question.type === Utils.QUESTION_TYPE.SINGLE_CHOICE ||
      question.type === Utils.QUESTION_TYPE.MULTI_CHOICE
    ) {
      const answerList = [];
      question.answerList.forEach(answer => {
        answerList.push(answer.text);
      });
      projectQuestionInfo.answers = {};
      projectQuestionInfo.answers.answerList = answerList;
      projectQuestionInfo.answers.randomOrder = question.randomAnswerOrder;
    } else projectQuestionInfo.answers = {};

    project.questions.push(projectQuestionInfo);
  });

  return project;
}

function setProject(commit, activeProject) {
  // commit("SET_PROJECT_THUMBNAIL", activeProject.thumbnail);

  // AUDIENCE
  commit("audience/INITIALIZE", activeProject, { root: true });

  // QUESTIONS
  commit("question/INIT_QUESTION_LIST", activeProject.questions, {
    root: true
  });

  // CHECKOUT
  commit("checkout/INITIALIZE", activeProject.payment, { root: true });

  // WIZARD
  commit("wizard/SET_ACTIVE_PROJECT_PACKAGE", activeProject.type, {
    root: true
  });
  commit("wizard/SET_ACTIVE_STEP", activeProject.step, {
    root: true
  });
  commit("wizard/SET_LAST_ACTIVE_STEP", activeProject.step, {
    root: true
  });
}

function groupProjects(projects) {
  const projectGroupedList = [
    {
      label: "pending",
      status: Utils.PROJECT_STATUS.PENDING,
      list: []
    },
    {
      label: "active",
      status: Utils.PROJECT_STATUS.ACTIVE,
      list: []
    },
    {
      label: "closed",
      status: Utils.PROJECT_STATUS.CLOSED,
      list: []
    }
  ];

  let index;
  projects.forEach(project => {
    switch (project.status) {
      case Utils.PROJECT_STATUS.PENDING:
        index = 0;
        break;
      case Utils.PROJECT_STATUS.ACTIVE:
        index = 1;
        break;
      case Utils.PROJECT_STATUS.CLOSED:
        index = 2;
        break;
      default:
        index = -1;
        break;
    }
    index > -1 && projectGroupedList[index].list.push(project);
  });

  return projectGroupedList;
}

function checkRedirectionByStatus(status, commit) {
  if (status === AUTHORIZATION_ERROR_STATUS) {
    commit("auth/SET_IS_LOGGED_IN", false, { root: true });
    router.replace({ name: "Login" });
  }
}

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions
};
