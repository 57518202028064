import swaggerClient from "./../plugins/swagger-client";

const tag = "project analytics";

export default class AnalysisService {
  retrieveNorms() {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.utils.retrieveNormsV11(
          {},
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveAnalysisData({ clientId, projectId }) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis[tag].retrieveProjectAnalysis(
          {
            clientId,
            projectId
          },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveVideoDataBySharingToken({ sharingToken, projectId, clientId }) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis[tag].retrieveProjectAnalyticsUsingToken(
          {
            token: sharingToken,
            projectId,
            clientId
          },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveSharingToken({ clientId, projectId }) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis[tag].retrieveProjectAnalyticsToken(
          {
            clientId,
            projectId
          },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
