import Utils from "./../Utils";
const strict = true;

const getDefaultState = () => {
  return {
    creditCard: {
      company: "",
      VATNumber: "",
      address: "",
      country: "",
      postalCode: "",
      city: "",
      province: "",
      recipientCode: "",
      PEC: "",
      purchaseOrderNumber: "",
      acceptTerms: false
    }
  };
};

const state = getDefaultState();

const getters = {
  getCreditCardCompany: state => state.creditCard.company,
  getCreditCardVATNumber: state => state.creditCard.VATNumber,
  getCreditCardAddress: state => state.creditCard.address,
  getCreditCardCountry: state => state.creditCard.country,
  getCreditCardCountryName: state => state.creditCard.country.name,
  getCreditCardCountryCode: state => state.creditCard.country.code,
  getCreditCardPostalCode: state => state.creditCard.postalCode,
  getCreditCardCity: state => state.creditCard.city,
  getCreditCardProvince: state => state.creditCard.province,
  getCreditCardRecipientCode: state => state.creditCard.recipientCode,
  getCreditCardPEC: state => state.creditCard.PEC,
  getCreditCardPurchaseOrderNumber: state =>
    state.creditCard.purchaseOrderNumber,
  isCreditCardAcceptTermsSelected: state => state.creditCard.acceptTerms
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  INITIALIZE(state, payment) {
    state.creditCard.company = payment.company;
    state.creditCard.VATNumber = payment.vat;
    state.creditCard.address = payment.address;
    const countryInfo = Utils.COUNTRY_LIST.find(
      country => country.name === payment.country,
      { root: true }
    );
    state.creditCard.country = countryInfo;
    state.creditCard.postalCode = payment.zip;
    state.creditCard.city = payment.city;
    state.creditCard.province = payment.province;
    state.creditCard.recipientCode = payment.recipientCode;
    state.creditCard.PEC = payment.pec;
    state.creditCard.purchaseOrderNumber = payment.po;
    state.creditCard.acceptTerms = payment.acceptedTerms;
  },
  SET_CREDIT_CARD_COMPANY(state, company) {
    state.creditCard.company = company;
  },
  SET_CREDIT_CARD_VAT_Number(state, VATNumber) {
    state.creditCard.VATNumber = VATNumber;
  },
  SET_CREDIT_CARD_ADDRESS(state, address) {
    state.creditCard.address = address;
  },
  SET_CREDIT_CARD_COUNTRY(state, country) {
    state.creditCard.country = country;
  },
  SET_CREDIT_CARD_POSTAL_CODE(state, postalCode) {
    state.creditCard.postalCode = postalCode;
  },
  SET_CREDIT_CARD_CITY(state, city) {
    state.creditCard.city = city;
  },
  SET_CREDIT_CARD_PROVINCE(state, province) {
    state.creditCard.province = province;
  },
  SET_CREDIT_CARD_RECIPIENT_CODE(state, recipientCode) {
    state.creditCard.recipientCode = recipientCode;
  },
  SET_CREDIT_CARD_PEC(state, PEC) {
    state.creditCard.PEC = PEC;
  },
  SET_CREDIT_CARD_PURCHASE_ORDER_NUMBER(state, purchaseOrderNumber) {
    state.creditCard.purchaseOrderNumber = purchaseOrderNumber;
  },
  SET_CREDIT_CARD_ACCEPT_TERMS(state, acceptTerms) {
    state.creditCard.acceptTerms = acceptTerms;
  }
};

const actions = {};

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions
};
