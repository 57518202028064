/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import Utils from "./../Utils";
import orderBy from "lodash/orderBy";
const strict = true;

const getDefaultState = () => {
  return {
    countryOptions: [],
    selectedCountry: "",
    viewerOptions: [
      { text: "TV", value: "standard" },
      { text: "Facebook", value: "facebook" },
      { text: "Instagram", value: "instagram" },
      { text: "TikTok", value: "tiktok" },
      { text: "Youtube", value: "youtube" },
    ],
    panelProviders:[
      {text:"Dynata - Provider",value:Utils.PANEL_PROVIDERS.DYNATA},
      {text:"Pure Spectrum - Provider",value:Utils.PANEL_PROVIDERS.PURE_SPECTRUM}
    ],
    selectedPanelProvider:Utils.PANEL_PROVIDERS.DEFAULT, // TODO, default now is 'dynata' it should eventually change
    selectedViewer: "",
    brandCategoryOptions: [],
    brandCategory: "",
    targetGroup: Utils.AUDIENCE_TARGET_GROUP.NATIONAL,
    activeRefineAudience: false,
    genderOptions: ["male", "female"],
    selectedGenderList: [],
    ageRangeOptions: {
      [Utils.AUDIENCE_TARGET_GROUP.NATIONAL]: [
        "group18_25",
        "group26_35",
        "group36_45",
        "group46_55",
        "group56_75",
      ],
      [Utils.AUDIENCE_TARGET_GROUP.PRIMARY]: [
        "group18_34",
        "group35_54",
        "group55_64",
      ],
    },
    selectedAgeRangeList: [],
    selectedRegionList: [],
    respondentOptions: [50, 150, 300],
    selectedRespondent: null,
    numberOfRespondents: null,
  };
};

const state = getDefaultState();

const getters = {
  getCountryOptions: (state) => state.countryOptions,
  getSelectedCountry: (state) => state.selectedCountry,
  getViewerOptions: (state) => state.viewerOptions,
  getPanelProviderOptions:(state)=> state.panelProviders,
  getSelectedViewer: (state) => state.selectedViewer,
  getBrandCategory: (state) => state.brandCategory,
  getBrandCategoryOptions: (state) => state.brandCategoryOptions,
  getTargetGroup: (state) => state.targetGroup,
  getActiveRefineAudience: (state) => state.activeRefineAudience,
  getGenderOptions: (state) => state.genderOptions,
  getSelectedGenderList: (state) => state.selectedGenderList,
  getAgeRangeOptions: (state) => state.ageRangeOptions[state.targetGroup],
  getSelectedAgeRangeList: (state) => state.selectedAgeRangeList,
  getSelectedRegionList: (state) => state.selectedRegionList,
  getRespondentOptions: (state) => state.respondentOptions,
  getSelectedRespondent: (state) => state.selectedRespondent,
  getNumberOfRespondents: (state) => state.numberOfRespondents,
  getselectedPanelProvider:(state)=>state.selectedPanelProvider,
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach((key) => {
      if (key !== "brandCategoryOptions" && key !== "countryOptions")
        state[key] = s[key];
    });

    /* Initialize */
    state.selectedGenderList = [...state.genderOptions];
    state.selectedAgeRangeList = [...state.ageRangeOptions[state.targetGroup]];
  },
  INITIALIZE(state, project) {
    const audience = project.audience;
    state.selectedPanelProvider = project.panelProvider;
    state.selectedViewer = project.viewer;
    state.selectedCountry =
      state.countryOptions.find((country) => country.id === audience.country) ||
      "";

    state.targetGroup = audience.population;

    state.brandCategory = audience.category ? audience.category : "";

    state.selectedGenderList = [];
    state.activeRefineAudience = audience.refineAudience;
    for (const [key, value] of Object.entries(audience.genre)) {
      if (value === true) state.selectedGenderList.push(key);
    }

    state.selectedAgeRangeList = [];
    for (const [key, value] of Object.entries(audience.ageGroup)) {
      if (value === true) state.selectedAgeRangeList.push(key);
    }

    if (state.selectedCountry !== "") {
      switch(state.selectedPanelProvider){
        case 'pure-spectrum':
          state.selectedRegionList = state.selectedCountry.regionsPureSpectrum.filter(
            (region) => {
              return state.selectedCountry.regionsPureSpectrum.includes(region.option_id);
            }
          );
          break;
        case 'dynata':
        default:
          state.selectedRegionList = state.selectedCountry.regions.filter(
            (region) => {
              return state.selectedCountry.regions.includes(region.option_id);
            }
          );
          break;
      }
      
    } else {
      state.selectedRegionList = [];
    }
  },
  SET_COUNTRY_OPTIONS(state, countryOptions) {
    state.countryOptions = countryOptions;
  },
  SET_SELECTED_COUNTRY(state, country) {
    let countryRegions;
    state.selectedCountry = country;
    if(country==='')return;
    switch(state.selectedPanelProvider){
      case 'pure-spectrum': countryRegions = state.selectedCountry.regionsPureSpectrum; break;
      case 'dynata':
      default:
        countryRegions = country.regions; break;
    }
    state.selectedRegionList = countryRegions;
  },
  SET_BRAND_CATEGORY_OPTIONS(state, categoryOptions) {
    state.brandCategoryOptions = categoryOptions;
  },
  SET_BRAND_CATEGORY(state, brandCategory) {
    state.brandCategory = brandCategory;
  },
  SET_SELECTED_VIEWER(state, viewer) {
    state.selectedViewer = viewer;
  },
  SET_SELECTED_PANEL_PROVIDER(state,panelProvider){
    state.selectedPanelProvider = panelProvider;
  },
  SET_TARGET_GROUP(state, targetGroup) {
    state.targetGroup = targetGroup;
    state.selectedAgeRangeList = [...state.ageRangeOptions[state.targetGroup]];
  },
  SET_ACTIVE_REFINE_AUDIENCE(state, refineAudience) {
    state.activeRefineAudience = refineAudience;
  },
  SET_SELECTED_GENDER_LIST(state, genderList) {
    state.selectedGenderList = genderList;
  },
  SET_SELECTED_AGE_RANGE_LIST(state, ageRangeList) {
    state.selectedAgeRangeList = ageRangeList;
  },
  SET_SELECTED_REGION_LIST(state, regionList) {
    state.selectedRegionList = regionList;
  },
  SET_SELECTED_RESPONDENT(state, selectedRespondent) {
    state.selectedRespondent = selectedRespondent;
  },
  SET_NUMBER_OF_RESPONDENTS(state, numberOfRespondents) {
    state.numberOfRespondents = numberOfRespondents;
  },
};

const actions = {
  retrieveProjectCategories({ commit }, showError = true) {
    return services.CategoryService.retrieveProjectCategories(state.selectedPanelProvider)
      .then((response) => {
        commit("SET_BRAND_CATEGORY_OPTIONS", response);
      })
      .catch((error) => {
        commit("SET_BRAND_CATEGORY_OPTIONS", []);
        console.log(error);
        if (showError) {
          commit(
            "error/SET_ERROR",
            {},
            {
              root: true,
            }
          );
        }
        return Promise.reject();
      });
  },
  retrieveCountries({ commit }) {
    return services.ProjectService.retrieveCountries()
      .then((response) => {
        // console.log(response);
        const sortedCountryList = sortCountryList(response);
        commit("SET_COUNTRY_OPTIONS", sortedCountryList);
      })
      .catch((error) => {
        commit("SET_COUNTRY_OPTIONS", []);
        console.log(error);
        commit(
          "error/SET_ERROR",
          {},
          {
            root: true,
          }
        );
        return Promise.reject();
      });
  },
};

function sortCountryList(countryList) {
  const SORT_BY = "asc";
  return orderBy(
    countryList,
    (country) => country.countryName.toLowerCase(),
    SORT_BY
  );
}

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions,
};
