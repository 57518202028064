import UserService from "./services/UserService";
import ProjectService from "./services/ProjectService";
import CategoryService from "./services/CategoryService";
import BillingService from "./services/BillingService";
import AnalysisService from "./services/AnalysisService";

export default {
  UserService: new UserService(),
  ProjectService: new ProjectService(),
  CategoryService: new CategoryService(),
  BillingService: new BillingService(),
  AnalysisService: new AnalysisService()
};
