/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import router from "./../router/index";

const strict = true;
const AUTHORIZATION_ERROR_STATUS = 401;

const getDefaultState = () => {
  return {
    branches: [],
    users: [],
  };
};

const state = getDefaultState();

const getters = {
  getBranches: (state) => state.branches,
  getUsers: (state) => state.users,
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  SET_BRANCHES(state, branches) {
    state.branches = branches;
  },
  SET_USERS(state, users) {
    state.users = users;
  },
};

const actions = {
  retrieveClientUsers({ commit }) {
    return services.UserService.retrieveUsers()
      .then((response) => {
        commit("SET_USERS", response);
      })
      .catch((error) => {
        console.log(error);
        commit("SET_USERS", []);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject();
      });
  },
  createClientUser({ commit, dispatch }, payload) {
    return services.UserService.createUserAccount(payload)
      .then(() => {
        dispatch("retrieveClientUsers");
      })
      .catch((error) => {
        console.log(error);
        checkRedirectionByStatus(error.status, commit);
        const messageText =
          error.response.body.errorDetails === "Email is not available"
            ? "emailNotAvailable"
            : null;
        commit(
          "error/SET_ERROR",
          { messageText },
          {
            root: true,
          }
        );
        return Promise.reject();
      });
  },
  updateClientUser({ commit, dispatch }, payload) {
    const userId = payload._id;
    return services.UserService.updateUser(userId, payload)
      .then((response) => {
        dispatch("retrieveClientUsers");
      })
      .catch((error) => {
        console.log(error);
        commit("SET_USERS", []);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject();
      });
  },
  deleteClientUser({ commit, getters }, userId) {
    return services.UserService.deleteUser(userId)
      .then(() => {
        const updatedUsers = getters.getUsers.filter((user) => {
          return user._id !== userId;
        });
        commit("SET_USERS", updatedUsers);
      })
      .catch((error) => {
        console.log(error);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject();
      });
  },
  createBranchByClientId({ commit, rootGetters }, payload) {
    const clientId = rootGetters["auth/getClientId"];
    return services.UserService.createBranchByClientId(clientId, payload)
      .then((response) => {
        commit("auth/SET_USER_ACCOUNT", response, {
          root: true,
        });
        commit("SET_BRANCHES", response.branches);
      })
      .catch((error) => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject();
      });
  },
  updateBranchByClientIdAndBranchId({ commit, rootGetters }, payload) {
    const clientId = rootGetters["auth/getClientId"];
    const branchId = payload._id;
    return services.UserService.updateBranchByClientIdAndBranchId(
      clientId,
      branchId,
      payload
    )
      .then((response) => {
        commit("auth/SET_USER_ACCOUNT", response, {
          root: true,
        });
        commit("SET_BRANCHES", response.branches);
      })
      .catch((error) => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject();
      });
  },
  deleteBranchByClientIdAndBranchId({ commit, rootGetters }, branchId) {
    const clientId = rootGetters["auth/getClientId"];
    return services.UserService.deleteBranchByClientIdAndBranchId(
      clientId,
      branchId
    )
      .then((response) => {
        commit("auth/SET_USER_ACCOUNT", response, {
          root: true,
        });
        commit("SET_BRANCHES", response.branches);
      })
      .catch((error) => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject();
      });
  },
};

function checkRedirectionByStatus(status, commit) {
  if (status === AUTHORIZATION_ERROR_STATUS) {
    commit("SET_IS_LOGGED_IN", false);
    router.replace({ name: "Login" });
  }
}

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions,
};
